<template>
  <div>
    <sdPageHeader title="Panel">
      <template #buttons>
        <!--        <div class='page-header-actions'>-->
        <!--          <sdCalendarButton />-->
        <!--        </div>-->
      </template>
    </sdPageHeader>
    <Main>
      <a-row :gutter="20">
        <a-col
          :sm="8"
          :xs="12"
        >
          <OverviewCard>
            <div class="d-flex align-items-center justify-content-between overview-head">
              <sdHeading as="h4">
                {{ $t.wallet }}
              </sdHeading>
            </div>
            <div class="overview-box">
              <sdCards headless>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="overview-box-single">
                    <sdHeading
                      as="h2"
                      class="color-primary"
                    >
                      {{ last_payment }} ₺
                    </sdHeading>
                    <p>{{ $t.lastUploadedPayment }}</p>
                  </div>
                  <div class="overview-box-single text-right">
                    <sdHeading as="h2">
                      {{ total_payment }} ₺
                    </sdHeading>
                    <p>{{ $t.totalUploadedPrice }}</p>
                  </div>
                </div>
              </sdCards>
            </div>

            <div class="overview-box">
              <sdCards headless>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="overview-box-single">
                    <sdHeading
                      as="h2"
                      class="color-info"
                    >
                      {{ total_consume }} Adet
                    </sdHeading>
                    <p>{{ $t.amountSpent }}</p>
                  </div>
                  <div class="overview-box-single text-right">
                    <sdHeading as="h2">
                      {{ balance }} Adet
                    </sdHeading>
                    <p>{{ $t.balance }}</p>
                  </div>
                </div>
                <a-progress
                  :percent="percent"
                  :show-info="false"
                />
              </sdCards>
            </div>
          </OverviewCard>
        </a-col>
        <a-col
          :sm="8"
          :xs="12"
        >
          <LocationMapWrapper>
            <div class="full-width-table">
              <sdCards :title="$t.recordedCargo">
                <div class="location-map d-flex justify-content-center">
                  <WorldMap />
                </div>
                <div class="location-table theme-2">
                  <a-table
                    :columns="cargo_company_counts_columns"
                    :data-source="cargo_company_counts"
                    :pagination="false"
                    :show-header="false"
                  />
                </div>
              </sdCards>
            </div>
          </LocationMapWrapper>
        </a-col>
        <a-col
          :sm="8"
          :xs="12"
        >
          <sdCards headless>
            <OverviewSalesCard>
              <div class="icon-box box-success">
                <SvgConverter :src="require('../../static/img/icon/SalesRevenue.svg')" />
              </div>
              <div class="card-chunk">
                <CardBarChart2>
                  <h2>{{ total_shipment_count }}</h2>
                  <span>{{ $t.totalNumberPosts }}</span>
                </CardBarChart2>
              </div>
            </OverviewSalesCard>
          </sdCards>
          <sdCards
            :title="$t.paymentHistory"
            bodypadding="0px"
          >
            <a-row>
              <a-col
                :xxl="24"
                :md="24"
                :xs="24"
              >
                <RegionList>
                  <perfect-scrollbar
                    :options="{
                      wheelSpeed: 1,
                      swipeEasing: true,
                      suppressScrollX: true,
                    }"
                  >
                    <a-table
                      :columns="payment_history_columns"
                      :data-source="payment_history"
                      :pagination="false"
                      :row-key="row => row.date"
                      :show-header="false"
                    />
                  </perfect-scrollbar>
                </RegionList>
              </a-col>
            </a-row>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>

import { Main } from '../styled'
import SvgConverter from '@/components/utilities/SvgConverter.vue'
import WorldMap from '@/components/utilities/VectorMap.vue'
import { LocationMapWrapper, OverviewCard, OverviewSalesCard } from '../dashboard/style'


export default {
  name: 'MainPage',
  components: {
    Main,
    SvgConverter,
    WorldMap,
    LocationMapWrapper,
    OverviewSalesCard,
    OverviewCard,
  },
  data() {
    return {
      last_payment: 0,
      total_payment: 0,
      total_consume: 0,
      balance: 0,
      percent: 0,

      payment_history: [],

      total_shipment_count: 0,

      cargo_company_counts: [],

      cargo_company_counts_columns: [
        {
          title: 'Cargo',
          dataIndex: 'name',
        },
        {
          title: 'Count',
          dataIndex: 'count',
        },
      ],
      payment_history_columns: [
        {
          title: 'Miktar',
          dataIndex: 'quantity',
        },
        {
          title: 'Tarih',
          dataIndex: 'date',
        },
      ],
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      this.$restMethods.postForCargongWebApp('/index').then(response => {

        if ( ! response.hasError()) {

          const data = response.getData()

          this.last_payment = data.last_payment
          this.total_payment = data.total_payment
          this.total_consume = data.total_consume
          this.balance = data.balance
          this.percent = data.percent

          this.payment_history = data.payment_history

          this.total_shipment_count = data.total_shipment_count

          this.cargo_company_counts = data.cargo_company_counts

        }

      })

    },
  },
}
</script>
